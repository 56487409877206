import * as React from "react";
import { List } from "semantic-ui-react";

const ossJa = <a href="https://cybozu-oss-policy.readthedocs.io/ja/latest/" target="_blank" rel="noreferrer noopener">OSSポリシー(日本語)</a>;
const ossEn = <a href="https://cybozu-oss-policy.readthedocs.io/en/latest/" target="_blank" rel="noreferrer noopener">OSS Policy (English)</a>;
const ossJaGithub = <a href="https://github.com/cybozu/oss-policy" target="_blank" rel="noreferrer noopener">GitHub</a>;
const ossEnGithub = <a href="https://github.com/cybozu/oss-policy-en" target="_blank" rel="noreferrer noopener">GitHub</a>;

interface OssPolicyProps {
  inverted?: boolean;
}

const OssPolicy: React.FC<OssPolicyProps> = ({ inverted }) => (
  <List as="ul" relaxed="very" size="big" inverted={inverted}>
    <List.Item as="li">{ossJa} ({ossJaGithub})</List.Item>
    <List.Item as="li">{ossEn} ({ossEnGithub})</List.Item>
  </List>
)

export default OssPolicy;
