import * as React from "react";
import { List } from "semantic-ui-react";

const ossOpenSSFnewsJa = <a href="https://topics.cybozu.co.jp/news/2022/08/31-18229.html" target="_blank" rel="noreferrer noopener">サイボウズ、OpenSSF (Open Source Security Foundation) に加盟(日本語)</a>;

interface OpenSSFProps {
  inverted?: boolean;
}

const OpenSSF: React.FC<OpenSSFProps> = ({ inverted }) => (
  <List as="ul" relaxed="very" size="big" inverted={inverted}>
    <List.Item as="li">{ossOpenSSFnewsJa}</List.Item>
  </List>
)

export default OpenSSF;
