import * as React from "react";
import { Header, List } from "semantic-ui-react";

interface OSSDonationDestinationProp {
  name: string;
  url: string;
}

interface OSSDonationProp {
  year: number;
  destinations: OSSDonationDestinationProp[];
}

const OSSDonations: OSSDonationProp[] = [
  {
    year: 2018,
    destinations: [
      { name: "Software in the Public Interest (SPI)", url: "https://www.spi-inc.org/" },
      { name: "Mozilla Foundation", url: "https://foundation.mozilla.org/" },
      { name: "Free Software Foundation (FSF)", url: "https://www.fsf.org/" },
      { name: "Python Software Foundation (PSF)", url: "https://www.python.org/psf-landing/" },
      { name: "Lombok Project", url: "https://projectlombok.org/" },
    ],
  },
  {
    year: 2019,
    destinations: [
      { name: "Debian Project", url: "https://www.debian.org/" },
      { name: "Mozilla Foundation", url: "https://foundation.mozilla.org/" },
      { name: "Free Software Foundation (FSF)", url: "https://www.fsf.org/" },
      { name: "Let's encrypt", url: "https://letsencrypt.org/" },
      { name: "OpenBSD Foundation", url: "https://www.openbsdfoundation.org/" },
      { name: "PlantUML", url: "https://plantuml.com/" },
      { name: "ESLint", url: "https://eslint.org/" },
    ],
  },
  {
    year: 2020,
    destinations: [
      { name: "Debian Project", url: "https://www.debian.org/" },
      { name: "Mozilla Foundation", url: "https://foundation.mozilla.org/" },
      { name: "Free Software Foundation (FSF)", url: "https://www.fsf.org/" },
      { name: "Let's encrypt", url: "https://letsencrypt.org/" },
      { name: "OpenBSD Foundation", url: "https://www.openbsdfoundation.org/" },
      { name: "PlantUML", url: "https://plantuml.com/" },
      { name: "ESLint", url: "https://eslint.org/" },
      { name: "Ajv", url: "https://ajv.js.org/" },
      { name: "Babel", url: "https://babeljs.io/" },
      { name: "webpack", url: "https://webpack.js.org/" },
    ],
  },
  {
    year: 2021,
    destinations: [
      { name: "Debian Project", url: "https://www.debian.org/" },
      { name: "Mozilla Foundation", url: "https://foundation.mozilla.org/" },
      { name: "Free Software Foundation (FSF)", url: "https://www.fsf.org/" },
      { name: "Let's encrypt", url: "https://letsencrypt.org/" },
      { name: "OpenBSD Foundation", url: "https://www.openbsdfoundation.org/" },
      { name: "PlantUML", url: "https://plantuml.com/" },
      { name: "ESLint", url: "https://eslint.org/" },
      { name: "Ajv", url: "https://ajv.js.org/" },
      { name: "Babel", url: "https://babeljs.io/" },
      { name: "webpack", url: "https://webpack.js.org/" },
      { name: "Staticcheck", url: "https://staticcheck.io/" },
      { name: "Canonical", url: "https://canonical.com//" },
      { name: "core-js", url: "https://github.com/zloirock/core-js/" },
      { name: "curl", url: "https://curl.se/" },
      { name: "azu", url: "https://github.com/azu/" },
      { name: "PHP Foundation", url: "https://opencollective.com/phpfoundation" },
    ],
  },
  {
    year: 2022,
    destinations: [
      { name: "Debian Project", url: "https://www.debian.org/" },
      { name: "Mozilla Foundation", url: "https://foundation.mozilla.org/" },
      { name: "Free Software Foundation (FSF)", url: "https://www.fsf.org/" },
      { name: "Let's encrypt", url: "https://letsencrypt.org/" },
      { name: "OpenBSD Foundation", url: "https://www.openbsdfoundation.org/" },
      { name: "PlantUML", url: "https://plantuml.com/" },
      { name: "ESLint", url: "https://eslint.org/" },
      { name: "Ajv", url: "https://ajv.js.org/" },
      { name: "Babel", url: "https://babeljs.io/" },
      { name: "webpack", url: "https://webpack.js.org/" },
      { name: "Staticcheck", url: "https://staticcheck.io/" },
      { name: "Canonical", url: "https://canonical.com//" },
      { name: "core-js", url: "https://github.com/zloirock/core-js/" },
      { name: "curl", url: "https://curl.se/" },
      { name: "azu", url: "https://github.com/azu/" },
      { name: "PHP Foundation", url: "https://opencollective.com/phpfoundation" },
      { name: "pre-commit", url: "https://pre-commit.com/" },
      { name: "draw.io", url: "https://www.diagrams.net/" },
      { name: "Prettier", url: "https://prettier.io/" },
    ],
  },
  {
    year: 2023,
    destinations: [
      { name: "Free Software Foundation (FSF)" , url: "https://www.fsf.org/" },
      { name: "Mozilla Foundation" , url: "https://foundation.mozilla.org/" },
      { name: "Debian Project" , url: "https://www.debian.org/" },
      { name: "Let’s Encrypt" , url: "https://letsencrypt.org/" },
      { name: "OpenBSD Foundation" , url: "https://www.openbsdfoundation.org/" },
      { name: "ESLint" , url: "https://eslint.org/" },
      { name: "PlantUML" , url: "http://plantuml.com/" },
      { name: "Ajv" , url: "https://ajv.js.org/" },
      { name: "Babel" , url: "https://babeljs.io/" },
      { name: "webpack" , url: "https://webpack.js.org/" },
      { name: "Staticcheck" , url: "https://staticcheck.io/sponsors" },
      { name: "Canonical" , url: "https://canonical.com/" },
      { name: "core-js" , url: "https://github.com/zloirock/core-js" },
      { name: "curl" , url: "https://curl.se/" },
      { name: "PHP Foundation" , url: "https://opencollective.com/phpfoundation" },
      { name: "azu" , url: "https://github.com/sponsors/azu" },
      { name: "pre-commit " , url: "https://pre-commit.com/" },
      { name: "draw.io" , url: "https://www.diagrams.net/" },
      { name: "Prettier" , url: "https://prettier.io/" },
      { name: "mold" , url: "https://github.com/rui314/mold" },
      { name: "SWC" , url: "https://github.com/swc-project/swc" },
      { name: "Vite" , url: "https://github.com/vitejs/vite" },
      { name: "typescript-eslint" , url: "https://github.com/typescript-eslint/typescript-eslint" },
      { name: "3v4l" , url: "https://3v4l.org/" },
      { name: "Homebrew" , url: "https://brew.sh/" },
      { name: "Stylelint" , url: "https://stylelint.io/" },
      { name: "Storybook" , url: "https://storybook.js.org/" },
      { name: "date-fns" , url: "https://date-fns.org/" },
    ],
  },
  {
    year: 2024,
    destinations: [
      { name: "Free Software Foundation (FSF)" , url: "https://www.fsf.org/" },
      { name: "Mozilla Foundation" , url: "https://foundation.mozilla.org/" },
      { name: "Debian Project" , url: "https://www.debian.org/" },
      { name: "Let’s Encrypt" , url: "https://letsencrypt.org/" },
      { name: "OpenBSD Foundation" , url: "https://www.openbsdfoundation.org/" },
      { name: "ESLint" , url: "https://eslint.org/" },
      { name: "PlantUML" , url: "http://plantuml.com/" },
      { name: "Ajv" , url: "https://ajv.js.org/" },
      { name: "Babel" , url: "https://babeljs.io/" },
      { name: "webpack" , url: "https://webpack.js.org/" },
      { name: "Staticcheck" , url: "https://staticcheck.io/sponsors" },
      { name: "Canonical" , url: "https://canonical.com/" },
      { name: "core-js" , url: "https://github.com/zloirock/core-js" },
      { name: "curl" , url: "https://curl.se/" },
      { name: "PHP Foundation" , url: "https://opencollective.com/phpfoundation" },
      { name: "azu" , url: "https://github.com/sponsors/azu" },
      { name: "pre-commit " , url: "https://pre-commit.com/" },
      { name: "draw.io" , url: "https://www.diagrams.net/" },
      { name: "Prettier" , url: "https://prettier.io/" },
      { name: "mold" , url: "https://github.com/rui314/mold" },
      { name: "SWC" , url: "https://github.com/swc-project/swc" },
      { name: "Vite" , url: "https://github.com/vitejs/vite" },
      { name: "typescript-eslint" , url: "https://github.com/typescript-eslint/typescript-eslint" },
      { name: "3v4l" , url: "https://3v4l.org/" },
      { name: "Homebrew" , url: "https://brew.sh/" },
      { name: "Stylelint" , url: "https://stylelint.io/" },
      { name: "Storybook" , url: "https://storybook.js.org/" },
      { name: "date-fns" , url: "https://date-fns.org/" },
      { name: "Street Side Software" , url: "https://github.com/streetsidesoftware/cspell" },
      { name: "cowtowncoder" , url: "https://github.com/FasterXML/jackson" },
      { name: "mapstruct" , url: "https://mapstruct.org/" },
      { name: "immer" , url: "https://immerjs.github.io/immer/" },
    ],
  },
];

const OSSDonationList: React.FC = () => {
  return (
    <>
      {OSSDonations.sort((a, b) => b.year - a.year ).map((donation) => {
        const { year, destinations } = donation;
        return (
          <>
            <Header as="h3">{year}年</Header>
            <List.List as="ul">
              {destinations.sort((a, b) => {
                if (a.name < b.name) { return -1; }
                if (b.name < a.name) { return 1; }
                return 0;
              }).map((destination) => {
                const { name, url } = destination;
                return <List.Item key={destination.name} as="li"><a href={url} target="_blank" rel="noreferrer noopener">{name}</a></List.Item>;
              })}
            </List.List>
          </>
        );
      })}
    </>
  );
};

export default OSSDonationList

