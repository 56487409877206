import * as React from 'react'
import { Button, Dimmer, Image } from 'semantic-ui-react'

export interface DimmerCardImageProps extends React.HTMLProps<HTMLDivElement> {
  src: string;
  alt: string;
  href: string;
  video: string;
}

const DimmerCardImage: React.FC<DimmerCardImageProps> = ({ src, alt, href, video }) => {
  const [active, setActive] = React.useState(false);
  const activate = React.useCallback(() => setActive(true), [setActive])
  const inactivate = React.useCallback(() => setActive(false), [setActive])

  const content = (
    <div className="dimmer card image">
      <Button as="a" href={href} target="_blank" rel="noreferrer noopener">
        資料を見る
      </Button>
      {
        video != null ?
          <Button as="a" href={video} target="_blank" rel="noreferrer noopener">動画を見る</Button> :
          null
      }
    </div>
  );

  return (
    <Dimmer.Dimmable
      as={Image}
      dimmed={active}
      dimmer={{ active, content }}
      onMouseEnter={activate}
      onMouseLeave={inactivate}
      size='medium'
      src={src}
      alt={alt}
    />
  );
}

export default DimmerCardImage;
