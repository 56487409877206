import * as React from "react";
import { Card, Image } from "semantic-ui-react";

interface GitHubOrgProp {
  logo: string;
  name: string;
  url: string;
  description: string;
}

const GitHubOrgs: GitHubOrgProp[] = [
  {
    logo: "https://avatars1.githubusercontent.com/u/2433152?s=200&v=4",
    name: "Cybozu",
    url: "https://github.com/cybozu",
    description: "Open source products from Cybozu",
  },
  {
    logo: "https://avatars2.githubusercontent.com/u/17453711?s=200&v=4",
    name: "Cybozu Go",
    url: "https://github.com/cybozu-go",
    description: "Go products from Cybozu",
  },
  {
    logo: "https://avatars0.githubusercontent.com/u/4815054?s=200&v=4",
    name: "kintone",
    url: "https://github.com/kintone",
    description: "",
  },
];

export const GitHubOrgCards: React.FC = () => {
  return (
    <Card.Group doubling centered>
      {GitHubOrgs.map((prop: GitHubOrgProp) => {
        const { name, url, logo, description } = prop;
        return (
          <Card key={name} link href={url} target="_blank" rel="noreferrer noopener">
            <Card.Content>
              <Image floated="right" size="mini" src={logo} alt={`Icon: ${name}`} />
              <Card.Header>{name}</Card.Header>
              <Card.Description>{description}</Card.Description>
            </Card.Content>
          </Card>
        );
      })}
    </Card.Group>
  );
};

export default GitHubOrgCards;
